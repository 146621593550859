import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import Blockquote from "../components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"

const galleryItems = [
  {
    key: 1,
    title: "Top Draft Sports Bar",
    images: ["/images/projects/top-draft-project1.jpg"],
  },
  {
    key: 2,
    title: "Back Painted Glass Process",
    images: ["/images/projects/top-draft-project2.jpg"],
  },
  {
    key: 3,
    title: "Finished Pannels",
    images: ["/images/projects/top-draft-project3.jpg"],
  },
]

const TexturedPatternGlassRitzCarltonMiamiFl = () => {
  return (
    <PageLayout>
      <SEO
        title="Textured Pattern Glass | Ritz Carlton Key Biscayne Miami, FL | ArtVue Glass"
        ogDescription="ArtVue's textured pattern glass was fabricated by hand for this project located in Key Biscayne, Miami. The result is a modern design that compliments the luxury of the property and sets itself apart from other hotels on the island."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>Ritz Carlton Key Biscayne, Miami, FL</h1>
            <p>
              Clear textured glass. Architectural glass. Obscure glass. Patterned glass. They are all different phrases with the same meaning. Glass with a textured surface that allows for more privacy while offering quality light transmission—and easily laminated for added safety, UV, and acoustical benefits.
            </p>
            <p>
              The Ritz Carlton Key Biscayne project is an excellent example of how designers, decorators, and architects can utilize a single textured pattern glass for different applications while still offering variation and maintaining a consistent design theme.
            </p>
            <p>
              Using our 5/32” thick Thin Reed pattern glass as the foundational product, this glass was utilized three different ways within the bar and dining area.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>1. Pattern Glass for Light Diffusion and Design Accents</h2>
            <p>
              <img
                src="/images/projects/ritz-carlton-project1.jpg"
                alt="Textured Pattern Glass For Light Diffusion And Design Accents"
              />
            </p>
            <p>
              For the floating overhead island above the bar, over 60 triangles were cut by hand to ensure the Thin Reed pattern was vertically oriented.  It was like a giant puzzle fitting each piece into the framing, with the result appearing to be one solid, wrap-around, uniform feature.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>2. Obscure Glass for Privacy, Acoustics and Light</h2>
            <p>
              <img
                src="/images/projects/ritz-carlton-project2.jpg"
                alt="Obscure Glass for Privacy, Acoustics and Light"
              />
            </p>
            <p>
              A portion of the dining area was positioned adjacent to a busy hallway. Instead of completely closing off the seating area with a solid wall, the designers and architects included a fixed glass partition.  The glass partition was comprised of two pieces of 5/32” thick Thin Reed pattern glass laminated together with an EVA interlayer.  The laminated configuration allows light to pass through, providing patrons the feeling of more space, while also dampening the sound transmission by as much as 10 decibals.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>3. Textured Glass for Privacy, Safety & Convenience</h2>
            <p>
              <img
                src="/images/projects/ritz-carlton-project3.jpg"
                alt="Textured Glass for Privacy, Safety & Convenience"
              />
            </p>
            <p>
              The last application incorporated the same 5/32” thick Thin Reed pattern glass, again in laminated form, for the entrance doors into the restaurant.  The use of pattern glass provides separation between different spaces without feeling completely closed off.  The opaque nature of textured glass also helps patrons to see if someone else is entering/exiting at the same time.  And since the glass panels are laminated, in the event of an accident, glass pieces will remain adhered to the EVA interlayer for added safety.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request A Quote",
                size: "tiny",
                icon: "file pdf outline",
              }}
            >
              <RequestQuote />
            </FormWrapper>
          </div>
        }
      />
    </PageLayout>
  )
}

export default TexturedPatternGlassRitzCarltonMiamiFl